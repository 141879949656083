const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://5ow6wwgdkf.execute-api.us-east-1.amazonaws.com/prod',
    TARGET_API_URL: 'https://v1f4suq24i.execute-api.us-east-1.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.staging.rossing.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.8.0',
    HOSTNAME: 'https://targets.staging.rossing.forwoodsafety.com'
  }
};

export default config;